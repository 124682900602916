import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBContainer, MDBRow } from 'mdbreact'
import ContactMain from '../components/contactMain'
import OfficeDepartments from '../components/cardIconsOffices'
import OfficeLocations from '../components/columnAlternatingOffices'

const Contact = ({ data }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark

  return (
    <>
      <Layout>
        <div id="sub-page">          
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Need to get in touch?"
            subtitle="Fill out the form below and we'll get back to you."
            type="contact"
            alt="hand holding a paper plane ready to throw"
          />
        </div>

        <main>
            <ContactMain />

            <section className="bg-light-blue" id="departments">
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.departments.title}
                </h3>
                {post.frontmatter.departments.contactdeets && (
                  <MDBRow>
                    <>
                      {post.frontmatter.departments.contactdeets.map((details, index) => {
                          return (
                            <OfficeDepartments
                              key={index}
                              depttitle={details.depttitle}
                              tel={details.tel}
                              email={details.email}
                              suptext={details.suptext}
                              image={details.image.childImageSharp.gatsbyImageData}
                              alttext={details.alttext}
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
          </section>

            <section className="bg-white" id="locations">
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large pb-5">
                  {post.frontmatter.locations.title}
                </h3>
                {post.frontmatter.locations.offices.map((officelocations, index) => {
                    return (
                      <OfficeLocations
                        key={index}
                        office={officelocations.office}
                        address={officelocations.address}
                        tel={officelocations.tel}
                        email={officelocations.email}
                        lat={officelocations.lat}
                        lng={officelocations.lng}
                        placement={officelocations.placement}
                      />
                    )
                  }
                )}
              </MDBContainer>
          </section>

        </main>
      </Layout>
    </>
  )
}
export default Contact

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Contact Us"
      description="Want more information on iET Solutions products and services? Please get in touch using the contact details or web form provided."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "contact" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        section {
          title
          subtitle
          description
        }
        departments {
          title
          contactdeets {
            depttitle
            tel
            email
            suptext
            alttext
            image {
            childImageSharp {
              gatsbyImageData(width: 85, quality: 90) 
            }
           }
          }
        }        
        title
        subtitle
        locations {
          title
          offices {
            office
            address
            tel
            email
            lat
            lng
            placement
          }
        }        
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
